import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import Expandible from "../../../layout/HelperDataTable/Expandible";
import { FilterComponent, useCompleteTableConfig } from "../../../constants";
import { dataAcuerdosITE } from "../../../data/dataAcuerdos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const TableRow = ({ title, url }) =>
  title && url ? (
    <tr>
      <td>{title.toUpperCase()}</td>
      <td>
        <PdfLink url={url} />
      </td>
    </tr>
  ) : null;

const AcuerdosTableITE = ({ year }) => {
  const is2015 = year === "2015";
  const yearData = dataAcuerdosITE[year] || (is2015 ? { iet: [], ite: [] } : []);

  // Configuración de columnas común
  const commonColumns = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        footer: "MES",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorKey: "id",
        id: "acuerdo",
        header: "ACUERDO",
        footer: "ACUERDO",
        size: 55,
        Cell: ({ cell }) => `ITE-CG ${cell.getValue()}-${year}`,
      },
      {
        accessorFn: (row) =>
          row.nameDoc ? `${row.typeDoc} ${row.nameDoc}` : "",
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    [year]
  );

  // Configuración de columnas específica para IET 2015
  const iet2015Columns = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        footer: "MES",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorKey: "id",
        id: "acuerdo",
        header: "ACUERDO",
        footer: "ACUERDO",
        size: 55,
        Cell: ({ cell }) => `IET-CG ${cell.getValue()}-${year}`,
      },
      {
        accessorFn: (row) =>
          row.nameDoc ? `${row.typeDoc} ${row.nameDoc}` : "",
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    [year]
  );

  // Panel de detalles optimizado
  const renderDetailPanelAcuerdos = useMemo(() => ({ row }) => {
    const anexoTitles = Object.keys(row.original).filter(
      (key) => key.startsWith("titleAnexo") && row.original[key]
    );

    const isIET2015 = year === "2015" && row.original.__isIET;
    const prefix = isIET2015 ? "IET-CG" : "ITE-CG";

    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-40">
            <thead>
              <tr>
                <td colSpan={2}>
                  <br />
                  <strong>A C U E R D O</strong>
                  <br />
                  <br />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <td>{`${row.original.typeDoc} ${prefix} ${
                  row.original.id
                }-${year} ${row.original.nameDoc || ""}`}</td>
                <td>
                  {row.original.id && (
                    <PdfLink url={`${year}/${row.original.id}.pdf`} />
                  )}
                </td>
              </tr>
              {anexoTitles.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <br />
                    <strong>A N E X O</strong>
                    <br />
                    <br />
                  </td>
                </tr>
              )}
              {anexoTitles.map((key) => {
                const index = key.replace("titleAnexo", "");
                return (
                  <TableRow
                    key={index}
                    title={row.original[key]}
                    url={`${year}/${row.original.id}.${index}.pdf`}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    );
  }, [year]);

  // Preparamos los datos para IET 2015
  const prepareIET2015Data = useMemo(() => {
    return is2015 ? yearData.iet.map(item => ({ ...item, __isIET: true })) : [];
  }, [is2015, yearData.iet]);

  // Preparamos las configuraciones de tabla
  const tableConfigIet = useCompleteTableConfig(
    prepareIET2015Data,
    iet2015Columns,
    renderDetailPanelAcuerdos
  );

  const tableConfigIte = useCompleteTableConfig(
    is2015 ? yearData.ite : [],
    commonColumns,
    renderDetailPanelAcuerdos
  );

  const tableConfigNormal = useCompleteTableConfig(
    !is2015 ? yearData : [],
    commonColumns,
    renderDetailPanelAcuerdos
  );

  if (is2015) {
    return (
      <>
        <div className="margin-bottom-2">
          <Breadcrumbs
            path={[
              { label: "Acuerdos Anteriores", url: "/AcuerdosAnteriores" },
              { label: `Acuerdos ${year}` },
            ]}
          />
          <TitlePages title="Acuerdos" subTitle={`Acuerdos ${year}`} />
          <Expandible />

          {/* Tabla IET */}
          <h3 className="text-center my-4">ACUERDOS IET 2015</h3>
          <MaterialReactTable table={tableConfigIet} />

          {/* Tabla ITE */}
          <h3 className="text-center my-4">ACUERDOS ITE 2015</h3>
          <MaterialReactTable table={tableConfigIte} />
        </div>
      </>
    );
  }

  // Render normal para otros años
  return (
    <>
      <div className="margin-bottom-2">
        <Breadcrumbs
          path={[
            { label: "Acuerdos Anteriores", url: "/AcuerdosAnteriores" },
            { label: `Acuerdos ${year}` },
          ]}
        />
        <TitlePages title="Acuerdos ITE" subTitle={`Acuerdos ITE ${year}`} />
        <Expandible />
        <MaterialReactTable table={tableConfigNormal} />
      </div>
    </>
  );
};

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

AcuerdosTableITE.propTypes = {
  year: PropTypes.string.isRequired,
};

export default AcuerdosTableITE;