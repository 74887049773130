export const dataLicitaciones = [
    {
        num: "1",
        nombreLicitacion: "LICITACIÓN PÚBLICA NACIONAL ITE-LP-01-2025",
        link: `https://itetlax.org.mx/assets/pdf/licitaciones/1.pdf`, 
    },
    {
        num: "2",
        nombreLicitacion: "LICITACIÓN PÚBLICA NACIONAL ITE-LP-02-2025",
        link: `https://itetlax.org.mx/assets/pdf/licitaciones/2.pdf`,
    },
    {
        num: "3",
        nombreLicitacion: "LICITACIÓN PÚBLICA NACIONAL ITE-LP-03-2025",
        link: `https://itetlax.org.mx/assets/pdf/licitaciones/3.pdf`,
    },
    {
        num: "4",
        nombreLicitacion: "LICITACIÓN PÚBLICA NACIONAL ITE-LP-04-2025",
        link: `https://itetlax.org.mx/assets/pdf/licitaciones/4.pdf`,
    },
];
