export const dataCIndex = [
  {
    id: 1,
    imgUrl: "personas_extranjeras.webp",
    alt: "personas_extranjeras",
    url: "https://itetlax.org.mx/assets/pdf/carousel/CONVOCATORIA_PE.pdf",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "Cintillo.webp",
    alt: "Cintillo",
    url: "https://itetlax.org.mx/assets/pdf/carousel/CONVOCATORIA_OBSERVACION_ELECTORAL.pdf",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "libroITE.webp",
    alt: "Libro ITE",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroITE.pdf",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "comunidades.webp",
    alt: "micrositioComunidades",
    url: "https://ite-comunidades.itetlax.org.mx",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "Convocatoria.webp",
    alt: "CALENDARIO",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/Convocatoria.pdf",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "eleccionjudiciallocal.webp",
    alt: "Elección Judicial",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/ListadoActualizado.pdf",
    linkToo: "",
  },



  {
    id: 7,
    imgUrl: "Convocatoria-ITE-LP-02-2025.webp",
    alt: "ITE-LP-02-2025",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/licitaciones/2.pdf",
    linkToo: "",
  },

  {
    id: 8,
    imgUrl: "Convocatoria-ITE-LP-03-2025.webp",
    alt: "ITE-LP-03-2025",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/licitaciones/3.pdf",
    linkToo: "",
  },

  {
    id: 9,
    imgUrl: "Resultados.png",
    alt: "Resultados",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/FOLIOS calificaciones SEL y CAEL_PELEPJ 2024-2025.pdf",
    linkToo: "",
  },

  {
    id: 10,
    imgUrl: "DesignaciondelCargo.webp",
    alt: "DesignaciondelCargo",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/FOLIOS DESIGNACIONES SEL y CAEL_PEEPJ 2024-2025.pdf",
    linkToo: "",
  },
  
  {
    id: 11,
    imgUrl: "CAEL.webp",
    alt: "CAEL",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/CONVOCATORIA CAEL.pdf",
    linkToo: "",
  },

  {
    id: 12,
    imgUrl: "1Integracion.webp",
    alt: "CAEL",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/Integracion.pdf",
    linkToo: "",
  },
  
  {
    id: 13,
    imgUrl: "2Listas.webp",
    alt: "CAEL",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/ListasReserva.pdf",
    linkToo: "",
  },
  {
    id: 14,
    imgUrl: "ConvocatoriaConsejerias.webp",
    alt: "ConvocatoriaConsejerias",
    itemActive: "",
    buttonActive: "",
    url: "https://itetlax.org.mx/assets/pdf/carousel/Tlaxcala_18-03-2025.pdf",
    linkToo: "",
  },
  {
    id: 15,
    imgUrl: "Folios.webp",
    alt: "Folios",
    itemActive: "active",
    buttonActive: "active",
    url: "https://itetlax.org.mx/assets/pdf/carousel/Folios.pdf",
    linkToo: "",
  },
];



export const dataCAR = [
  {
    id: 1,
    imgUrl: "0.png",
    alt: "Convocatoria OPPMT",
    url: "https://www.observatoriomujerestlaxcala.mx/contents/",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "11.png",
    alt: "siceen21",
    url: "https://siceen21.ine.mx/home",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "apoyo_ciudadano.png",
    alt: "Convocatoria",
    url: "https://itetlax.org.mx/assets/img/imgC_AR/apoyo_ciudadano.png",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "RedMujeres.webp",
    itemActive: "active",
    buttonActive: "active",
    alt: "Red Mujeres",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSdZb2vkV6LQwamOR7zY3XC_gIHwkq-qbuQoAiWm6rQWQx6Ktg/viewform",
    linkToo: "",
  },
 
];

export const dataCA = [


  {
    id: 1,
    imgUrl: "7.jpg",
    alt: "Participación Política de las mujeres tlaxcaltecas",
    itemActive: "active",
    buttonActive: "active",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroOPPMT.pdf",
    linkToo: "",
  },

  {
    id: 2,
    imgUrl: "46.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "47.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "48.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "49.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "50.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
];
